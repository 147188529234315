<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 19:34:24
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-28 20:26:17
-->
<template>
  <div class="month_gmv">
    <a-table :data-source="tableData" bordered rowKey="id" :pagination="page" @change="changeTable" :columns="columns"></a-table>
  </div>
</template>

<script>
  import { YearSituationColumns } from './components/colums.js'
  import { YearSituationData } from './components/TableData.js'
  export default {
    data() {
      return {
        columns: YearSituationColumns,
        tableData: [],
        page: {
          pageNumber: 1,
          pageSize: 10,
          total: 0
        }
      }
    },
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.axios.get('/api/customer/customer/customerInfo/listCustomerYearTurnover', {params: this.page}).then(res => {
          this.tableData = res.body.records
          this.page.total = res.body.total
        })
      },
       /**
       * 更改分页
       */
      changeTable(pagination) {
        this.page = pagination
        this.page.pageNumber = pagination.current
        this.getData()
      },
    }
  }
</script>

<style lang="scss">
  
</style>